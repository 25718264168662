import React from "react";
import * as styles from "./footer.module.css";
import insta from "./insta.svg";
import fb from "./fb.svg";
import twitter from "./twitter.svg";
import "@fontsource/lato";

const Footer = () => {
  return (
    <footer>
      <nav className={styles.navbar}>
        <div className={styles.center}>
          <p>&copy; {new Date().getFullYear()} Carbae.in</p>
        </div>

        <div className={styles.footer_top}>
          <div>
            <a
              href="https://www.instagram.com/carbae.in_/"
              rel="noreferrer"
              target="_blank"
            >
              <img src={insta} alt="instaIcon" className={styles.icon} />
            </a>
            <a
              href="https://www.facebook.com/carbae.in/"
              rel="noreferrer"
              target="_blank"
            >
              <img src={fb} alt="fbIcon" className={styles.icon} />
            </a>
            <a href="https://x.com/Carbae_in/" rel="noreferrer" target="_blank">
              <img src={twitter} alt="twitterIcon" className={styles.icon} />
            </a>
          </div>
        </div>
      </nav>
    </footer>
  );
};

export default Footer;
