import { Link } from "gatsby";
import React from "react";
import * as styles from "./header.module.css";
import logo from "./logo.png";
import logoImg from "./logoImg.png";
const Header = () => {
  return (
    <nav className={styles.navbar}>
      <a href="https://www.carbae.in/" target="_blank">
        <div className={styles.logo}>
          <img src={logoImg} alt="CarBae Logo" />
          <img src={logo} alt="CarBae Logo" />
        </div>
      </a>
      <div className={styles.nav_right}>
        <Link to="/">Home</Link>
      </div>
    </nav>
  );
};

export default Header;
